<template>
  <div class="sourceForm">
    <div class="bottom-main">
      <div class="item-main">
        {{ title }}
        <v-input
          :width="394"
          v-model="params.name"
          :clearable="true"
          :placeholder="placeholder"
          ref="input"
        />
        <v-button text="搜索" class="ml" @click="getSearch()"></v-button>
        <div class="item-right">
          <v-button
            :text="btnText"
            class="item-btn"
            @click="goNextAdd()"
          ></v-button>
        </div>
      </div>

      <div
        class="item-card"
        :style="height"
        v-loading="loading"
        id="scrollWrap"
      >
        <div class="empty" v-if="showNone">暂无数据！</div>
        <div class="card" v-for="(item, index) in content" :key="index">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>{{ item.name }}</span>
              <div class="opera" v-if="isShowOpear">
                <v-tooltip
                  :content="tipContent"
                  placement="top"
                  :manual="false"
                >
                  <img
                    src="./img/view.png"
                    alt=""
                    @click="handelClickView(item)"
                  />
                </v-tooltip>
                <span v-if="isShowIcon">
                  <v-tooltip content="开启任务" placement="top" :manual="false">
                    <img
                      v-if="item.status == 0"
                      src="./img/play.png"
                      alt=""
                      @click="handelClick(item.id, 0)"
                    />
                  </v-tooltip>
                  <v-tooltip content="关闭任务" placement="top" :manual="false">
                    <img
                      v-if="item.status == 1"
                      src="./img/stop.png"
                      alt=""
                      @click="handelClick(item.id, 1)"
                    />
                  </v-tooltip>
                </span>
                <v-tooltip content="删除" placement="top" :manual="false">
                  <img src="./img/delect.png" alt="" @click="del(item.id)" />
                </v-tooltip>
              </div>
              <div class="opera" v-else>
                <v-tooltip content="删除" placement="top" :manual="false">
                  <img src="./img/delect.png" alt="" @click="del(item.id)" />
                </v-tooltip>
              </div>
            </div>
            <div class="item-bottom" @click="goNextEvent(item)">
              <div class="text item">
                <div v-if="isShow">
                  <div
                    class="text item"
                    v-for="ite in showAry"
                    :key="ite"
                    :title="`${item[showObj][ite]}`"
                  >
                    {{ showTitle[ite] }} : {{ item[showObj][ite] }}
                  </div>
                </div>
                <div v-if="isOtherFileds">
                  <div
                    class="text item"
                    v-for="(ite, idx) in otherFildeShowAry"
                    :key="idx"
                    :title="`${item[ite]}`"
                  >
                    {{ otherFildeShowTitle[ite] }} :
                    {{ item[ite] | formatData }}
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "element-ui";
export default {
  name: "search",
  components: {
    elCard: Card,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "添加",
    },
    placeholder: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "height:100%",
    },
    searchUrl: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      // `{}`
      default() {
        return {};
      },
    },
    queryMethod: {
      type: String,
      // `get`
      default: "get",
    },
    showAry: {
      type: Array,
      // `{}`
      default() {
        return ["url", "username", "sql"];
      },
    },
    isShow: {
      type: Boolean,
      // `true`
      default: true,
    },
    isOtherFileds: {
      type: Boolean,
      // `true`
      default: false,
    },
    showTitle: {
      type: Object,
    },

    showObj: {
      type: String,
      default: "config",
    },
    otherFildeShowAry: {
      type: Array,
      default() {
        return ["url", "username", "sql"];
      },
    },
    otherFildeShowTitle: {
      type: Object,
    },
    isShowOpear: {
      type: Boolean,
      default: false,
    },
    // 是否展示暂停/播放图标
    isShowIcon: {
      type: Boolean,
      default: true,
    },
    //提示内容
    tipContent: {
      type: String,
      default: "查看数据",
    },
  },
  data() {
    return {
      searchParam: {
        value: "",
      },
      searchAry: [],
      content: [],
      loading: true,
      showNone: false,
      pageTotal: 1,
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      if (document.getElementById("scrollWrap")) {
        const selectWrap = document.getElementById("scrollWrap");
        selectWrap.addEventListener("scroll", this.scrollLoadMore);
      }
    });
  },
  filters: {
    formatData(val) {
      const formatDataObj = {
        0: "全量同步",
        1: "增量同步",
      };
      if (val === 0 || val === 1) {
        return formatDataObj[val];
      } else {
        return val;
      }
    },
  },
  methods: {
    /**
     * 监听滚动条
     * scrollTop为滚动条在Y轴上的滚动距离。
     * clientHeight为内容滚动区域的高度。
     * scrollHeight为内容可视区域的高度加上溢出（滚动）的距离。
     * */
    scrollLoadMore(e) {
      const scrollTop = e.target.scrollTop; // 滚动条滚动时，距离顶部的距离
      const windowHeight = e.target.clientHeight; // 可视区的高度
      const scrollHeight = e.target.scrollHeight; // 滚动条的总高度
      // // 滚动条到底部
      // if (scrollTop + windowHeight > scrollHeight) {
      //   this.params.curPage++;
      //   // if (this.params.curPage > this.pageTotal) return;
      //   this.getList(this.params);
      //   console.log(this.params.curPage);
      // } else {
      //   this.params.curPage = 1;
      // }

      if (this.params.curPage <= this.pageTotal) {
        //到底部了 重新请求数据
        this.params.curPage++; //页码++
        //TODO 执行加载数据方法
        this.getList(this.params);
      }
    },
    del(id) {
      this.loading = true;
      this.$emit("del", id);
    },
    // 点击查看
    handelClickView(item) {
      this.$emit("goViewInfo", item);
    },
    // 点击停止播放图标
    handelClick(id, operate) {
      this.$emit("getTaskStatus", id, operate);
    },
    goNextAdd() {
      this.$emit("goNextAdd");
    },
    goNextEvent(item) {
      this.$emit("goNextEvent", item);
    },
    getSearch() {
      this.loading = true;
      this.params.curPage = 1;
      this.getList(this.params);
    },
    getList(params) {
      // @vuese
      // 获取表单数据
      // @arg 请求参数对象
      this.$nextTick(() => {
        let _this = this;
        let queryConfig = {
          method: _this.queryMethod,
          url: _this.searchUrl,
        };
        if (_this.queryMethod.toLowerCase() == "get") {
          if (_this.queryMethodTe) {
            queryConfig.data = params;
          } else {
            queryConfig.params = params;
          }
        } else {
          if (_this.queryMethodTe) {
            queryConfig.params = params;
          } else {
            queryConfig.data = params;
          }
        }
        if (_this.queryContentType) {
          queryConfig.headers["Content-Type"] = _this.queryContentType;
        }
        if (_this.queryTransformRequest) {
          queryConfig.transformRequest = _this.queryTransformRequest;
        }

        if (params.curPage === 1) {
          this.content = [];
        }

        _this.$axios(queryConfig).then((res) => {
          if (res.code === 200) {
            this.loading = false;
            this.pageTotal = res.data.pages;
            if (res.data.records.length == 0) {
              if (params.curPage === 1) {
                this.showNone = true;
              }
            } else {
              res.data.records.forEach((item, index) => {
                if (typeof item[this.showObj] == "object") {
                  item[this.showObj] = item[this.showObj];
                } else if (
                  typeof item[this.showObj] == "string" &&
                  item[this.showObj]
                ) {
                  item[this.showObj] = JSON.parse(item[this.showObj]);
                }
              });
              this.content = this.content.concat(res.data.records);
              this.showNone = false;
            }
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  width: 2px; /* 纵向滚动条*/
  height: 0px; /* 横向滚动条 */
  background-color: #fff;
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #ccc;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.sourceForm {
  box-sizing: border-box;
  height: 100%;
  .bottom-main {
    padding: 20px;
    height: calc(100% - 50px);
    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
    }
    .item-main {
      display: flex;
      align-items: center;
      .item-right {
        display: flex;
        flex: 1;
        flex-direction: row-reverse;
        .item-bth {
        }
      }
    }
    .item-card {
      margin-top: 50px;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      overflow-y: auto;
      .card {
        width: 370px;
        border-radius: 8px;
        text-align: center;
        margin-left: 30px;
        margin-bottom: 20px;
        .box-card {
          // margin: 20px;
          .clearfix {
            display: flex;
            position: relative;
            align-content: center;
            span {
              display: inline-block;
              margin: 0 auto;
            }
            .opera {
              display: flex;
              position: absolute;
              right: 0;
              img {
                cursor: pointer;
                width: 18px;
                height: 18px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    .ml {
      margin-left: 20px;
    }
    // .item-card div:last-child {
    //   /* 让最后一个元素的右边距自动适应，从而实现左对齐的效果。*/
    //   margin-right: auto;
    // }
  }
  .text {
    font-size: 14px;
    text-align: left;
  }
  .item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 18px;
  }
  .empty {
    width: 100%;
    text-align: center;
    padding-top: 50px;
  }
  .item-bottom {
    padding: 20px;
    cursor: pointer;
  }
}
</style>
