<template>
  <div class="sourceForm">
    <div class="bottom-wrap">
      <search-data
        :showObj="'apiConfig'"
        :showAry="showAry"
        :showTitle="showTitle"
        ref="search"
        :title="'集市名称：'"
        :placeholder="'请输入关键词'"
        :searchUrl="getMarketList"
        :params="params"
        @goNextEvent="goEditPage"
        @goNextAdd="goAdd"
        @del="deleteSource"
        :isOtherFileds="true"
        :otherFildeShowAry="fildeAarry"
        :otherFildeShowTitle="fildeTitle"
      ></search-data>
    </div>
    <div>
      <v-dialog
        :title="title"
        v-model="dialogFit"
        width="50%"
        @close="closeDialog"
        @open="openDialog"
        @cancel="cancel"
        @confirm="submit"
        sureTxt="完成"
      >
        <form-panel
          :labelWidth="'120px'"
          ref="formPanel"
          :form="form"
          :hasHeader="false"
          :label-position="'left'"
          class="form-panel"
        >
          <div class="title">基本信息</div>
          <el-form-item
            label="集市名称"
            :rules="[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ]"
            prop="name"
          >
            <v-input placeholder="请输入名称" v-model="form.name"></v-input>
          </el-form-item>
          <div class="title">绑定资源</div>

          <el-form-item
            label="选择分析"
            :rules="[
              {
                required: true,
                message: '请选择分析',
                trigger: 'blur',
              },
            ]"
            prop="dataOfflineanalysisId"
          >
            <el-select
              v-model="form.dataOfflineanalysisId"
              :clearable="true"
              width="188px"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="title">结果获得</div>

          <el-form-item
            label="获取方式"
            :rules="[
              {
                required: true,
                message: '请选择获取方式',
                trigger: 'blur',
              },
            ]"
            prop="type"
          >
            <v-select
              v-model="form.type"
              clearable
              :options="getTypeOptions"
              @change="handleSelect"
            />
          </el-form-item>

          <div v-if="form.type == 1">
            <el-form-item
              label="接口简称"
              :rules="[
                {
                  required: true,
                  message: '请输入接口简称',
                  trigger: 'blur',
                },
              ]"
              prop="shortName"
            >
              <v-input
                placeholder="请输入名称"
                v-model="form.shortName"
                @blur="inputBlur"
              ></v-input>
            </el-form-item>
            <el-form-item
              label="接口地址"
              :rules="[
                {
                  required: true,
                  message: '请输入接口地址',
                  trigger: 'blur',
                },
              ]"
              prop="path"
            >
              <v-input
                placeholder="请输入接口地址"
                v-model="form.path"
              ></v-input>
            </el-form-item>
          </div>
          <div v-if="form.type == 2">
            <el-form-item
              label="消息信息"
              :rules="[
                {
                  required: true,
                  message: '请输入消息信息',
                  trigger: 'blur',
                },
              ]"
              prop="loginName"
            >
              <v-input
                placeholder="请输入消息信息"
                v-model="form.loginName"
                type="textarea"
              ></v-input>
            </el-form-item>

            <el-form-item
              label="服务器地址"
              :rules="[
                {
                  required: true,
                  message: '请输入服务器地址',
                  trigger: 'blur',
                },
              ]"
              prop="loginName"
            >
              <v-input
                placeholder="请输入服务器地址"
                v-model="form.loginName"
                type="text"
              ></v-input>
            </el-form-item>
            <el-form-item
              label="topic信息"
              :rules="[
                {
                  required: true,
                  message: '请输入topic信息',
                  trigger: 'blur',
                },
              ]"
              prop="loginName"
            >
              <v-input
                placeholder="请输入topic信息"
                v-model="form.loginName"
                type="text"
              ></v-input>
            </el-form-item>
          </div>
        </form-panel>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import searchData from "../components/search/index.vue";
import {
  getMarketList,
  deleteMarketList,
  getAnalysisList,
  submitMarketData,
  getMarketDetail,
  getMarketLocation,
} from "./api.js";
export default {
  components: {
    searchData,
  },
  data() {
    return {
      getMarketList,
      params: {
        curPage: 1,
        pageSize: 12,
        name: undefined,
        type: undefined,
      },
      showAry: ["path"],
      showTitle: {
        path: "接口地址",
      },
      dialogFit: false,
      title: "",
      dialogComputed: false,
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      headers: [
        {
          prop: "problemName",
          label: "名称",
        },
        {
          prop: "problemType",
          label: "备注",
        },
        {
          prop: "ifRequired",
          label: "类型",
        },
      ],
      options: [],
      getTypeOptions: [
        {
          value: 1,
          label: "数据接口",
        },
        {
          value: 2,
          label: "消息订阅",
        },
      ],
      form: {
        id: "", // 数据唯一值
        dataOfflineanalysisId: "",
        name: "", // 姓名（昵称）
        type: "",
        shortName: "",
        path: "",
      },
      fildeAarry: ["typeName"],
      fildeTitle: {
        typeName: "获取方式",
      },
    };
  },
  mounted() {
    this.$refs.search.getList(this.params);
  },
  methods: {
    //编辑
    goEditPage(arg) {
      this.title = "编辑";
      this.form.id = arg.id;
      this.$axios.get(`${getMarketDetail}?id=${arg.id}`).then((res) => {
        if (res.code === 200) {
          this.dialogFit = true;
          const { name, dataOfflineanalysisId, type, apiConfig } = res.data;

          this.form.name = name;
          this.form.dataOfflineanalysisId = parseInt(dataOfflineanalysisId);
          this.form.type = type;
          const dataList = JSON.parse(apiConfig);
          this.form.path = dataList.path;
          this.form.shortName = dataList.shortName;
          console.log(res);
        }
      });
    },
    //新增
    goAdd() {
      this.dialogFit = true;
      this.title = "新建";
    },
    //获取分析列表
    openDialog() {
      let params = {
        pageSize: 10,
        curPage: 1,
      };
      this.$axios.get(`${getAnalysisList}`, { params }).then((res) => {
        if (res.code === 200) {
          this.options = res.data.records;
          this.loading = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.initFromData();
    },
    //提交表单新增和编辑
    submit() {
      this.form.apiConfig = JSON.stringify({
        path: this.form.path,
        shortName: this.form.shortName,
      });
      const result = this.$refs.formPanel.validate();
      if (result === true) {
        this.$axios
          .post(`${submitMarketData}`, this.form, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            if (res.code === 200) {
              this.params.curPage = 1;
              this.$refs.search.getList(this.params);
              this.$message.success("保存成功");
              this.initFromData();
              this.dialogFit = false;
              // this.filtInit();
              // this.dataInit();
            } else {
              this.$message.success("保存失败");
            }
          });
      }
    },
    //取消
    cancel() {
      this.dialogFit = false;
      this.initFromData();
    },
    initFromData() {
      this.form = {
        id: "", // 数据唯一值
        dataOfflineanalysisId: "",
        name: "", // 姓名（昵称）
        type: "",
        shortName: "",
        path: "",
      };
    },
    //获取集市接口地址
    inputBlur() {
      const params = {
        name: this.form.name,
        dataOfflineanalysisId: this.form.dataOfflineanalysisId,
        type: 0,
        apiConfig: JSON.stringify({
          path: this.form.path,
          shortName: this.form.shortName,
        }),
      };
      this.$axios.post(`${getMarketLocation}`, params).then((res) => {
        const { data } = res;
        this.form.path = data;
      });
    },
    handleSelect(arg) {},
    //删除
    deleteSource(id) {
      this.$axios.post(`${deleteMarketList}?id=${id}`).then((res) => {
        if (res.code == 200) {
          this.params.curPage = 1;
          this.$refs.search.getList(this.params);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
.form-panel {
  padding: 0 30px;
}
.check-panel {
  display: flex;
}
.item-model {
  display: flex;
  justify-content: space-between;
}

.sourceForm {
  box-sizing: border-box;
  height: 100%;
  .bottom-wrap {
    padding: 30px 20px;
    height: calc(100% - 150px);
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .mt50 {
      margin-top: 30px;
    }
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 30px;
}
</style>
